<template>
  <div>
    <v-dialog
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :transition="
        $vuetify.breakpoint.smAndDown
          ? 'dialog-bottom-transition'
          : 'dialog-transition'
      "
      max-width="768"
    >
      <template #activator="{ on }">
        <v-btn
          color="primary"
          width="125"
          rounded
          :disabled="disabled"
          v-on="on"
          >Reschedule</v-btn
        >
      </template>
      <v-card>
        <v-toolbar dark color="secondary">
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title
            >Reschedule Appointment #{{ appointment.job_id }}</v-toolbar-title
          >
        </v-toolbar>
        <div class="py-6">
          <v-form
            ref="form"
            v-model="formIsValid"
            lazy-validation
            @submit.prevent="handleSubmit"
          >
            <v-card
              class="d-flex flex-column align-center justify-center grey--text text--darken-2 pa-2 bordered-card text-center mx-4 mb-4"
            >
              <p class="title ma-0">{{ appointment.service_category.name }}</p>
              <p class="caption ma-0">
                {{ address.street }},
                {{ address.zip_code }}
              </p>
              <p class="caption mt-2 mb-0">Currently scheduled for</p>
              <p class="ma-0 secondary--text">
                <b>{{ appointmentDate }}</b>
              </p>
              <p class="ma-0 secondary--text">
                <b>{{ appointmentWindow }}</b>
              </p>
            </v-card>
            <TimeSlots
              :service-category="appointment.service_category"
              :address="address"
              :handle-select-slot="handleSelectSlot"
            />
            <div class="text-center">
              <v-btn
                type="submit"
                color="primary"
                class="mt-6"
                rounded
                :loading="loading"
                :disabled="!selectedSlot"
                >Reschedule Appointment</v-btn
              >
            </div>
          </v-form>
        </div>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" color="success" top multi-line>
      <v-icon class="white--text mr-2">mdi-check</v-icon>
      <div class="d-flex flex-column full-width">
        <span>Rescheduled for</span>
        <span>{{ appointmentDate }} {{ appointmentWindow }}</span>
      </div>
      <v-btn color="white" icon text @click="snackbar = false">
        <v-icon>mdi-close-circle</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import API from "serviceshift-ui/api-client";
import { mapActions } from "vuex";
import { getAppointmentWindow, prettyFormat } from "@/lib/date";

import TimeSlots from "@/components/TimeSlots.vue";
import ValidationMixin from "@/mixins/validationRules";

export default {
  components: {
    TimeSlots
  },
  mixins: [ValidationMixin],
  props: {
    appointment: {
      type: Object,
      default: () => ({})
    },
    address: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false,
      loading: false,
      selectedSlot: null,
      snackbar: false
    };
  },
  computed: {
    appointmentDate() {
      return prettyFormat(new Date(this.appointment.scheduled_time));
    },
    appointmentWindow() {
      const timeZone = this.address.time_zone;
      const endTime = new Date(this.appointment.scheduled_time);

      return getAppointmentWindow(endTime, timeZone);
    }
  },
  methods: {
    ...mapActions(["fetchJob", "updateAppointment"]),
    async handleSubmit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        try {
          await this.updateAppointment({
            id: this.appointment.id,
            data: { scheduled_time: this.selectedSlot.scheduled_time }
          });
          this.fetchJob(this.appointment.job_id);
          this.dialog = false;
          this.snackbar = true;
          window.gtag("event", "reschedule", {
            event_category: "appointment"
          });
        } catch (e) {
          throw new Error(e);
        }
        this.loading = false;
      }
    },
    handleSelectSlot(slot) {
      this.selectedSlot = slot;
    }
  }
};
</script>

<style lang="scss" scoped></style>
