<template>
  <div class="full-width">
    <LateFeeTimer v-if="showLateFeeTimer" :appointment="jobAppointment" />
    <div class="map-container">
      <div ref="map" class="map elevation-3"></div>
      <div v-if="hasArrived">
        <div
          class="overlay eta d-flex px-3 py-2 elevation-2 flex-column text-left"
        >
          <div class="d-flex mr-3 flex-grow-1">
            <v-icon color="success" class="mr-1">mdi-check</v-icon>
            <span class="mr-2 d-flex align-center">
              <b>Technician has arrived!</b>
            </span>
          </div>
        </div>
      </div>
      <div v-else>
        <div
          v-if="!isActiveAppointment"
          class="overlay eta d-flex px-3 py-2 align-center elevation-2 flex-column"
          :class="{ desktop: !$vuetify.breakpoint.smAndDown }"
        >
          <div class="d-flex align-items-center">
            <i class="fa fa-truck mr-2 secondary--text truckOnTheWay" />
            <span class="mr-2">
              When your technician is on the way, track their drive on this map
              and see their real-time ETA!
            </span>
          </div>
        </div>

        <div
          v-else
          class="overlay eta d-flex px-3 py-2 elevation-2 flex-column text-left"
        >
          <div class="d-flex flex-column mr-3 flex-grow-1">
            <span class="mr-2">
              <b>Arriving at</b>
            </span>
          </div>

          <div class="d-flex justify-start align-baseline">
            <span class="headline primary--text">{{
              eta || "loading..."
            }}</span>
          </div>
        </div>
      </div>

      <div
        v-if="(isActiveAppointment || hasArrived) && jobAppointment.technician"
        class="overlay tech-info d-flex px-3 py-2 align-center elevation-2"
      >
        <v-avatar class="mr-2">
          <img :src="avatar" :alt="jobAppointment.technician.full_name" />
        </v-avatar>
        <div class="d-flex flex-column mr-2">
          <span>Your tech</span>
          <span
            class="subtitle-1 tech-name"
            :title="jobAppointment.technician.full_name"
          >
            {{ jobAppointment.technician.full_name }}
          </span>
        </div>

        <v-menu top offset-y transition="slide-y-reverse-transition">
          <template #activator="{ on }">
            <v-btn rounded outlined color="secondary" v-on="on"
              >Contact Tech</v-btn
            >
          </template>
          <v-list dense>
            <v-list-item
              link
              :href="`sms://${
                jobAppointment.technician.phone
              }?&body=${getTextBody()}`"
            >
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-message-processing</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Text</v-list-item-title>
            </v-list-item>
            <v-list-item link :href="`tel:${jobAppointment.technician.phone}`">
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-phone</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Call</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </div>
</template>

<script>
import { getRhumbLineBearing } from "geolib";
import { mapState } from "vuex";
import { mapActions } from "vuex";
import { getAddressName } from "@/lib/address";
import { formatByTimeZone, isTimeBefore } from "@/lib/date";

import LateFeeTimer from "@/components/LateFeeTimer.vue";
import AppointmentsChannel from "@/modules/cable/AppointmentsChannel";
import createCable from "@/modules/cable/createCable";

export default {
  name: "AppointmentMap",
  components: {
    LateFeeTimer
  },
  props: {
    techLastPosition: {
      type: Object,
      default: null
    },
    jobAppointment: {
      type: Object,
      default: () => ({})
    },
    address: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      currentTechPosition: {
        last: this.techLastPosition || null,
        current: null
      },
      eta: "",
      map: null,
      zoom: 15,
      customerLocation: new window.google.maps.LatLng(
        this.address.latitude || 0.0,
        this.address.longitude || -0.0
      ),
      customerMarker: null,
      technicianMarker: null,
      directionsService: new window.google.maps.DirectionsService(),
      route: null,
      cableChannel: null,
      trackableStatuses: ["accepted", "running"],
      poll: null
    };
  },
  computed: {
    ...mapState(["tenant"]),
    technicianPositionHeading() {
      if (!this.currentTechPosition.last || !this.currentTechPosition.current) {
        return 90;
      }
      return getRhumbLineBearing(
        this.currentTechPosition.last,
        this.currentTechPosition.current
      );
    },
    appointmentIsLate() {
      const { arrival_time, scheduled_time } = this.jobAppointment;
      const comparisonDateTime = arrival_time
        ? new Date(arrival_time)
        : new Date();
      return isTimeBefore(new Date(scheduled_time), comparisonDateTime);
    },
    technicianIconUrl() {
      return "../img/technician_map_icon.svg";
    },
    avatar() {
      // Prepend host if development
      if (!this.jobAppointment.technician) return "";
      return this.jobAppointment.technician.avatar.url;
    },
    isActiveAppointment() {
      return (
        this.jobAppointment.status === "accepted" &&
        this.jobAppointment.dispatch_time &&
        this.jobAppointment.technician
      );
    },
    addressName() {
      return getAddressName(this.address);
    },
    customer() {
      return this.jobAppointment.customer;
    },
    hasArrived() {
      return this.jobAppointment.status === "running";
    },
    showLateFeeTimer() {
      const { settings } = this.jobAppointment;
      const isLateFeeEnabled = settings.late_fee_per_minute_cents > 0;
      return isLateFeeEnabled && this.jobAppointmentIsLate;
    }
  },
  watch: {
    technicianPositionHeading: {
      handler(val) {
        this.$el
          .querySelector(".map-container")
          .style.setProperty("--technician-position-heading", `${val}deg`);
      }
    },
    jobAppointment(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getTechnicianLocation();
        this.setEta();
      }
    }
  },
  mounted() {
    this.loadAppointment();
    this.addCustomMapStyles();
    this.loadMap();
    this.pollAppointment();
    // Track page view
    window.gtag("event", "screen_view", {
      screen_name: "Appointment Map (Tracking)"
    });
  },
  beforeDestroy() {
    if (this.cableChannel) {
      this.cableChannel.disconnect();
    }
    clearInterval(this.poll);
  },
  methods: {
    ...mapActions(["fetchJob"]),

    // Google Maps does not support the idea of rotating a marker. Rotation can only exist on svg's with
    // path notation only (meaning we can't pull in custom SVG's with extra styling). This is a hack to apply
    // a CSS transformation on the technician image to capture orientation.
    addCustomMapStyles() {
      const styleEl = document.createElement("style");
      styleEl.id = "customStyles";
      styleEl.innerHTML = `
        img[src='${this.technicianIconUrl}'] { 
          transform: rotate(var(--technician-position-heading));
          transition: 1s; 
        }
      `;
      this.$el.append(styleEl);
    },
    pollAppointment() {
      clearInterval(this.poll);
      // Poll appointment every 60 seconds
      let timeOut = 5 * 60 * 1000;
      // Poll appt every 30 sec for late appointments
      if (this.jobAppointmentIsLate) {
        timeOut = 0.5 * 60 * 1000;
      }
      this.poll = setInterval(this.loadAppointment, timeOut);
    },
    async loadAppointment() {
      await this.fetchJob(this.jobAppointment.job_id);
      this.setEta();
    },
    getTextBody() {
      return `Hello%20${this.jobAppointment.technician.full_name}%20this%20is%20${this.$store.state.user.first_name}%2C%0A`;
    },
    connectToTrackingService() {
      if (!this.cableChannel) {
        const cable = createCable({
          token: this.jobAppointment.customer.cable_token,
          tenant: this.tenant
        });

        this.cableChannel = new AppointmentsChannel({
          id: this.jobAppointment.id
        });

        cable.subscribe(this.cableChannel).then(() => {
          this.cableChannel.on("technician-location", (payload) => {
            if (!this.trackableStatuses.includes(this.jobAppointment.status)) {
              this.cableChannel.disconnect();
              return;
            }

            const { position, routeMetadata } = payload;
            // Update tech marker on map
            this.updateTechnicianMarker(position.latitude, position.longitude);

            // Update eta
            if (routeMetadata.eta) {
              this.eta = formatByTimeZone(
                routeMetadata.eta,
                this.address.time_zone
              );
            }

            // Update route with new tech location
            if (this.technicianMarker) {
              this.setRoute(
                new window.google.maps.LatLng(
                  position.latitude,
                  position.longitude
                ),
                this.customerMarker.getPosition()
              );
              this.resetZoom();
            }
          });
        });
      }
    },
    async loadMap() {
      this.map = new window.google.maps.Map(this.$refs.map, {
        zoom: this.zoom,
        center: this.customerLocation,
        disableDefaultUI: true,
        clickableIcons: false
      });

      this.route = new window.google.maps.Polyline({
        strokeColor: "#05ACD5",
        strokeWeight: 4,
        geodesic: true
      });
      this.route.setMap(this.map);

      this.customerMarker = new window.google.maps.Marker({
        position: this.customerLocation,
        map: this.map,
        animation: google.maps.Animation.DROP
      });
      this.getTechnicianLocation();
    },
    getTechnicianLocation() {
      if (
        this.jobAppointment &&
        this.jobAppointment.technician &&
        this.trackableStatuses.includes(this.jobAppointment.status)
      ) {
        this.startTracking();
        if (this.techLastPosition) {
          const { latitude, longitude } = this.techLastPosition;
          this.updateTechnicianMarker(latitude, longitude);
          this.setRoute(
            new window.google.maps.LatLng(latitude, longitude),
            this.customerMarker.getPosition()
          );
          this.resetZoom();
        }
      }
    },
    resetZoom() {
      if (!this.technicianMarker || !this.customerMarker) {
        return;
      }

      let bounds = new window.google.maps.LatLngBounds();
      bounds.extend(this.technicianMarker.getPosition());
      bounds.extend(this.customerMarker.getPosition());
      this.map.fitBounds(bounds);
      this.map.panToBounds(bounds);
    },
    setRoute(currentLocation, destination) {
      if (!currentLocation || !destination) {
        return;
      }
      // Make sure google deps are loaded
      if (!google.maps.geometry) {
        setTimeout(() => {
          this.setRoute(currentLocation, destination);
        }, 500);
        return;
      }
      // Check if current location is on or off route
      if (this.isOnRoute(currentLocation, this.route)) {
        return;
      }
      // Redraw route
      this.directionsService.route(
        {
          destination,
          origin: currentLocation,
          travelMode: "DRIVING"
        },
        (response, status) => {
          if (status === "OK") {
            // Draw polyline route using path returned from directions service
            this.route.setOptions({
              path: response.routes[0].overview_path
            });
          } else {
            console.log(status);
          }
        }
      );
    },
    isOnRoute(currentLocation, polyline) {
      return google.maps.geometry.poly.isLocationOnEdge(
        currentLocation,
        polyline,
        0.001
      );
    },
    startTracking() {
      if (this.customer) {
        this.connectToTrackingService();
        // Track user started tracking
        window.gtag("event", "tracking", {
          event_category: "appointment"
        });
      } else {
        console.log("no cust: ", this.jobAppointment);
      }
    },
    updateTechnicianMarker(lat, lng) {
      this.currentTechPosition.last = this.currentTechPosition.current;
      this.currentTechPosition.current = { longitude: lng, latitude: lat };
      // Update existing marker
      if (this.technicianMarker) {
        return this.technicianMarker.setPosition(
          new window.google.maps.LatLng(lat, lng)
        );
      }
      // Create new marker
      this.technicianMarker = new window.google.maps.Marker({
        map: this.map,
        position: new window.google.maps.LatLng(lat, lng),
        animation: google.maps.Animation.DROP,
        icon: {
          anchor: new google.maps.Point(30, 30),
          url: this.technicianIconUrl,
          scaledSize: new window.google.maps.Size(60, 60)
        }
      });
    },
    setEta() {
      const date =
        this.jobAppointment.eta || this.jobAppointment.scheduled_time;
      this.eta = formatByTimeZone(date, this.address.time_zone);
    }
  }
};
</script>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 100%;
}
.map-container {
  width: 100%;
  height: 460px;
  position: relative;
}
.overlay {
  position: absolute;
  background: rgba(#fff, 0.9);
  font-size: 0.8em;
  border: 2px solid var(--v-secondary-base);
}
.eta {
  top: 10px;
  left: 0;
  border-radius: 0 6px 6px 0;
  border-left: none;
}
.tech-info {
  bottom: 10px;
  right: 0;
  border-radius: 6px 0 0 6px;
  border-right: none;
}
.tech-name {
  white-space: nowrap;
  font-weight: bold;
}
.desktop {
  max-width: 90%;
}
.truckOnTheWay {
  font-size: 24px;
}
</style>
