<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :transition="
      $vuetify.breakpoint.smAndDown
        ? 'dialog-bottom-transition'
        : 'dialog-transition'
    "
    max-width="768"
  >
    <template #activator="{ on }">
      <v-btn color="primary" width="125" rounded :disabled="disabled" v-on="on"
        >Cancel</v-btn
      >
    </template>
    <v-card>
      <v-toolbar dark color="secondary">
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title
          >Cancel Appointment #{{ appointment.job_id }}</v-toolbar-title
        >
      </v-toolbar>
      <div class="py-6 text-center">
        <v-form
          ref="form"
          v-model="formIsValid"
          lazy-validation
          @submit.prevent="handleSubmit"
        >
          <v-card
            class="d-flex flex-column align-center justify-center grey--text text--darken-2 pa-3 bordered-card text-center mx-4 mb-4"
          >
            <p class="title ma-0">{{ appointment.service_category.name }}</p>
            <p class="caption ma-0">
              {{ address.street }},
              {{ address.zip_code }}
            </p>
            <p class="caption mt-2 mb-0">Currently scheduled for</p>
            <p class="ma-0 secondary--text">
              <b>{{ appointmentDate }}</b>
            </p>
            <p class="ma-0 secondary--text">
              <b>{{ appointmentWindow }}</b>
            </p>
          </v-card>
          <p class="ma-4">
            Since we strive to continuously improve, please let us know why
            you're cancelling.
          </p>
          <v-card class="bordered-card d-flex flex-column pa-3 mb-6 mx-4">
            <v-select
              v-model="reason"
              outlined
              :items="items"
              label="Cancellation Reason"
              :rules="requiredRules"
            ></v-select>
            <v-textarea
              v-if="reason === 'Other...'"
              v-model="otherText"
              outlined
              label="Please Specify"
            ></v-textarea>
          </v-card>
          <v-btn type="submit" color="primary" rounded :loading="loading"
            >Cancel Appointment</v-btn
          >
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import { getAppointmentWindow, prettyFormat } from "@/lib/date";

import ValidationMixin from "@/mixins/validationRules";

export default {
  mixins: [ValidationMixin],
  props: {
    appointment: {
      type: Object,
      default: () => ({})
    },
    address: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false,
      reason: "",
      otherText: "",
      items: [
        "Conflicting schedule",
        "Used a different service",
        "Fixed it myself",
        "Other..."
      ],
      loading: false
    };
  },
  computed: {
    appointmentDate() {
      return prettyFormat(new Date(this.appointment.scheduled_time));
    },
    appointmentWindow() {
      const timeZone = this.address.time_zone;
      const endTime = new Date(this.appointment.scheduled_time);

      return getAppointmentWindow(endTime, timeZone);
    }
  },
  methods: {
    ...mapActions(["cancelAppointment"]),
    getCancellationReason() {
      if (this.reason === "Other...") {
        return `${this.reason}${this.otherText}`;
      }
      return this.reason;
    },
    async handleSubmit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        try {
          await this.cancelAppointment({
            id: this.appointment.id,
            data: { reason: this.getCancellationReason() }
          });
          this.$router.push({
            name: "serviceHistory",
            params: { cancelled: true }
          });
          window.gtag("event", "cancel", {
            event_category: "appointment"
          });
        } catch (e) {
          throw new Error(e);
        }
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
